<template>
    <div class="training-submission">
        <el-table :data="projectsSubmissionList" @current-change="handleCurrentChange"
                  border style="width: 100%; flex: 1;"  size="medium"
                  :header-cell-style="{fontWeight: 'normal', color: '#333', background: '#f5f7fa', textAlign: 'center'}"
                  :cell-style="{fontSize: '12px',color: '#333', textAlign: 'center'}">
            <el-table-column prop="rank" label="序号" width="100" ></el-table-column>
            <el-table-column prop="s_name" label="姓名"></el-table-column>
            <el-table-column prop="update_time" label="提交时间" align="center"></el-table-column>
            <el-table-column prop="create_time" label="评分" align="center">
                <template slot-scope="scope">
                    <div class="pf-act-content" v-if="role === 3">
                        <template v-if="scope.row.teacher_score">
                            <span>{{scope.row.teacher_score}}分</span>
                        </template>
                        <template v-else>
                            <el-input-number v-model="teacher_score" controls-position="right" class="pw-input"  :min="0" :max="100"></el-input-number>
                            <el-tooltip content="确定之后，则无法修改分数" placement="top-start">
                                <el-button type="primary" size="small" class="pw-confirm-btn" @click="submitContent(scope.row)">确定</el-button>
                            </el-tooltip>
                        </template>
                    </div>
                    <div v-if="role === 4">
                        <span v-if="scope.row.teacher_score">{{scope.row.teacher_score}}</span>
                        <span v-else >暂无评分</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <div @click="clickViewProjectDetail(scope.row)" class="detail-title">查看详情</div>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination v-if="role === 3"
                       class="pages-center" background
                       :current-page.sync="projectPages.currentPageNum"
                       :page-size="projectPages.eachPageNum"
                       :total="projectPages.total"
                       layout="prev, pager, next, jumper"
                       @current-change="projectCurrentChange">
        </el-pagination>
        <!--S 查看详情弹窗-->
        <el-dialog
                title="查看详情"
                :visible.sync="viewProjectDetail"
                width="600px"
                class="submit-detail">
            <el-scrollbar style="height: 100%;" :native="false" class="content">
                <div style="display: flex;min-height: 200px;">
                    <span class="detail-text" v-html="submit_projects_content"></span>
                </div>
                <div class="teacher-comments" v-if="role === 4">
                    <span class="text">教师评语</span>
                    <span class="comments" v-if="teacher_comment">{{teacher_comment}}</span>
                    <span class="comments" v-else >暂无评语</span>
                </div>
                <div class="teacher-comments" v-if="role === 3">
                    <span class="text">教师评语</span>
                    <el-input
                            class="textarea"
                            type="textarea"
                            :rows="6"
                            style="resize:none"
                            placeholder="请输入内容"
                            v-model="teacher_comment">
                    </el-input>
                </div>
            </el-scrollbar>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="viewProjectDetail = false" v-if="role === 4">确定</el-button>
                <el-button type="primary" @click="submitContent" v-if="role === 3">确定</el-button>
            </span>
        </el-dialog>
        <!--E 查看详情弹窗-->
    </div>
</template>

<script>
    export default {
        name: "ProjectSubmissionModule",
        data() {
            return {
                role: Number(localStorage.getItem('role')),
                //项目提交列表
                projectsSubmissionList: [],
                //提交内容
                submit_projects_content: '',
                //是否显示查看详情弹窗
                viewProjectDetail: false,
                //教师评语
                teacher_comment: '',
                //分数
                teacher_score: 0,
                //学生提交的id
                student_submit_id: '',
                //分页
                projectPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
                currentRow: null
            }
        },
        mounted() {
            this.getStudentProject();
        },
        methods: {
            //获取学生提交列表
            getStudentProject() {
                let param = {
                    // project_id: this.$route.query.p_id,
                    sx_id: this.$route.query.sx_id,
                    page: this.projectPages.currentPageNum,
                    limit: this.projectPages.eachPageNum
                }
                this.$shttp.axiosGetBy(this.$api.drill_showStuPutDrillList, param, (res) => {
                    if (res.code === 200) {
                        this.projectsSubmissionList = res.data.data;
                        this.projectPages.total = res.data.total;
                    } else {
                        this.$message.error(res.msg);
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //查看详情
            clickViewProjectDetail(row) {
                this.viewProjectDetail = true;
                this.submit_projects_content = row.content;
                this.teacher_comment = row.teacher_comment;
                this.student_submit_id = row.id;
            },
            handleCurrentChange(val) {
                if (val) {
                    this.currentRow = val;
                    this.student_submit_id = val.id;
                }
            },
            //提交评语/分数
            submitContent() {
                let formData = new FormData();
                formData.append('id', this.student_submit_id);
                if (this.teacher_comment) {
                    formData.append('teacher_comment', this.teacher_comment);
                }
                if (this.teacher_score) {
                    formData.append('teacher_score', this.teacher_score);
                }
                this.$shttp.axiosPost(this.$api.drill_teaCommentStuDrill, formData, (res) => {
                    if (res.code === 200) {
                        this.viewProjectDetail = false;
                        this.getStudentProject();
                        this.$message.success(res.msg);
                    } else {
                        this.$message.error(res.msg);
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //分页
            projectCurrentChange(val) {
                this.projectPages.currentPageNum = val;
                this.getStudentProject();
            },
        }
    }
</script>

<style scoped lang="scss">
    ul,li{
        margin: 0;
        padding: 0;
        list-style: none;
    }
    .training-submission{
        height: 100%;
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 10px;
        .detail-title{
            cursor: pointer;
            &.detail-title:hover{
                color: #409eff;
            }
        }
        .detail-text{
            margin-bottom: 10px;
            ::v-deep img {
                max-width: 100%;
                max-height: 100%;
            }
        }

        ::v-deep.el-dialog__header{
            border-bottom: 1px solid #eaeaea;
        }
        ::v-deep.el-dialog__footer{
            text-align: center;
        }
        ::v-deep.el-dialog__body{
            padding: 10px 20px;
        }
        ::v-deep.el-table__body-wrapper{
            height: calc(100% - 44px);
            overflow-y: auto;
        }
        ::v-deep.el-dialog__title{
            font-size: 14px;
        }
        ::v-deep .el-dialog {
            .el-dialog__body {
                height: 500px;
                box-sizing: border-box;
                ::v-deep .el-scrollbar__wrap {
                    overflow-x: hidden;
                }
            }
            .content {

            }
        }
    }
    .pages-center {
        margin-bottom: 20px;
    }
    .teacher-comments {
        display: flex;
        flex-direction: column;
        .comments {
            padding: 20px;
        }
        .textarea {
            padding-top: 20px;
        }
    }
    .pw-input {
        margin-right: 5px;
    }
</style>