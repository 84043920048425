<template>
    <div class="project-container">
        <div class="project-box">
            <project-info :id="id" :status="1" :tabs="tabs" :project="project" :app_lists="app_lists">
                <template v-if="role === 4" v-slot:extender>
                    <task-commit :ids="ids"></task-commit>
                </template>
                <template v-slot:extend>
                    <ProjectSubmissionModule ref="getProjectSubmitList"></ProjectSubmissionModule>
                </template>
            </project-info>
        </div>
    </div>
</template>

<script>
import TaskCommit from "@/components/material/TaskCommit";
import ProjectSubmissionModule from 'components/qualityCourse/ProjectSubmissionModule.vue'
import ProjectInfo from "@/components/material/ProjectInfo";
export default {
    name: "PracticalDetail",
    data() {
        return {
            activeName: 'first',
            id: this.$route.query.p_id ? Number(this.$route.query.p_id) : 0,
            ids: {
                project_id: this.$route.query.p_id ? Number(this.$route.query.p_id) : 0,
                sx_id: this.$route.query.sx_id ? Number(this.$route.query.sx_id) : 0
            },
            app_lists: [],
            project: {
                project_title: '',
                project_ability: [],
                project_attrs: [],
                project_evaluate: [],
                project_knowledge_link: [],
                project_sucai: [],
                project_task: [],
                project_duration: 0,
                project_difficulty: 0
            },
            tabs: ['实训详情', '实训提交'],
            role: localStorage.getItem('role') ? Number(localStorage.getItem('role')) : 3,
            //项目详情数据
            // projectDetailData: {},
        }
    },
    created() {
        this.projectInfo();
    },
    components: {
        ProjectSubmissionModule,
        ProjectInfo,
        TaskCommit
    },
    methods: {
        //点击tab
        handleClickItem() {
            this.$refs.getProjectSubmitList.getStudentProject();
        },
        //返回项目列表页
        goToProjectList() {
            this.$router.push({
                path: '/qualityCourse/Project'
            })
        },
        projectInfo() {
            this.$shttp.axiosGetBy(this.$api.getProjectDetail, { id: this.id }, (res) => {
                if (res.code === 200) {
                    this.project = Object.assign({}, res.data);
                    this.app_lists = [].concat(res.data.app_lists);
                }
            }, (err) => {
                this.$common.axiosErrorMsg(err)
            })
        }
    }
}
</script>

<style scoped lang="scss">
.project-container {
    display: flex;
    flex-direction: column;
    padding: 20px 70px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    .practical-details{
        height: calc(100% - 55px);
        display: flex;
        flex-direction: column;
        flex: 1;
        background-color: #fff;
    }
}
.project-box {
    width: 1200px;
    margin: 0 auto;
}
</style>